import React, { useEffect, useState, useRef, useCallback } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useNavigate, useParams } from 'react-router-dom';
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import Webcam from "react-webcam";
import Header from "../../Components/Header";
import { useDispatch, useSelector } from "react-redux";
import { addComplaint, addComplaintLive, getEnabledCategories, getEnabledProducts, getEnabledTypes  } from "../../reducers/commonReducer";
import VideoRecorder from "./VideoRecorder";
import VoiceRecorder from "./VoiceRecorder";
const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";
const videoConstraints = {
  facingMode: FACING_MODE_USER
};

const schema = yup.object().shape({
  type: yup.string().required("Please Select Complaint Type."),
  category: yup.string().required("Please Select Product Category."),
  product : yup.string().required("Please Select Product.")
});

function ComplaintAdd() {
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);
  const [img, setImg] = useState(null);
  const [photoCamera, setPhotoCamera] = useState(false);
  const [videoCamera, setVideoCamera] = useState(false);
  const [uservideo, setUservideo] = useState();
  const [audioRecord, setAudioRecord] = useState();
  const [audioStatus, setAudioStatus] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);
  const webcamRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const userid = queryParameters.get("userid");
  useEffect(() => {
    dispatch(getEnabledTypes())
    dispatch(getEnabledCategories())
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
        },
        (err) => {
          setError(err.message);
        }
      );
    } else {
      setError("Geolocation is not available in this browser.");
    }
      },[])
  const { getEnabledTypesData, getEnabledCategoriesData, getEnabledProductsData } = useSelector((state) => state.commonReducer);
  const blob = new Blob(recordedChunks, {
    type: "video/webm"
  });
  var reader = new FileReader();
  reader.readAsDataURL(blob); 
  reader.onloadend = function() {
  var base64data = reader.result;                
  setUservideo(base64data);
}
  const submitComplaint = async (values) => {
    console.log("values?.type", values?.type, values)
    const apiResponse = await dispatch(addComplaint({
      category :values?.category,
      type: values?.type,
      customer :values?.customer,
      image : values?.image ? values?.image : img,
      product :values?.product,
      summery :values?.summery,
      video : values?.video ? values?.video : uservideo,
      voice : values?.voice ? values?.voice : audioRecord,
      latitude: location?.latitude,
      longitude: location?.longitude,
      address:values?.address
    }));
    if (apiResponse?.payload?.status === 1) {
      return setTimeout(() => {
        navigate("/complaints");
      }, 1000);
    }
    return null;
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImg(imageSrc);
  }, [webcamRef]);
  const flipCamera = React.useCallback(() => {
    setFacingMode(
      prevState =>
        prevState === FACING_MODE_USER
          ? FACING_MODE_ENVIRONMENT
          : FACING_MODE_USER
    );
  }, []);
  return (
    <>
     <Header/>
      <div className="container">
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            dispatch(submitComplaint(values));
            resetForm({ values: "" });
          }}
          initialValues={{
            customer:userid,
            type: "",
            category: "",
            product: "",
            summery: '',
            voice: '',
            video: '',
            image: '',
            latitude: location?.latitude,
            longitude: location?.longitude,
            address:''
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            setFieldValue,
            errors,
          }) => (
            <Form className="container" onSubmit={handleSubmit}>
            <Row className="mt-5">
                <Col md={4} sm={12} className="mb-3">
                  <Form.Label>Complaint Regarding/के संबंध शिकायत</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={handleChange}
                    name="type"
                    className="form-control"
                    value={values.type}
                    isInvalid={!!errors.type}
                  >
                    <option value="">Complaint Regarding/के संबंध शिकायत</option>
                    {!! getEnabledTypesData && getEnabledTypesData?.map((data, index) => {
                      return (
                        <option value={data.id} key={index}>
                          {data.name === "Accessories" && `Accessories (Drum grid lines, Seives)` ||
                          data.name === "Breakage in Thresher" && `Breakage in Thresher (Bearing, Pulley broken)` ||
                          data.name === "Threshing Problem" && `Threshing Problem (Grain not clean, Grain coming in fans)`
                          }</option>
                      );  
                    })}
                    
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                      {errors.type}
                    </Form.Control.Feedback>
                </Col>
                <Col md={4} sm={12} className="mb-3">
                  <Form.Label>Select Thresher/थ्रेशर चुने</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      setFieldValue("category", e.target.value);
                      dispatch(getEnabledProducts({
                        "category" : e.target.value
                      }))
                    }}
                    name="category"
                    className="form-control"
                    value={values.category}
                    isInvalid={!!errors.category}
                  >
                    <option value="">Select Thresher/थ्रेशर चुने</option>
                    {!! getEnabledCategoriesData?.list && getEnabledCategoriesData.list.map((data, index) => {
                      return (
                        <option value={data.id} key={index}>{data.name}</option>
                      );  
                    })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                      {errors.category}
                    </Form.Control.Feedback>
                </Col>
                <Col md={4} sm={12} className="mb-3">
                  <Form.Label>Select Thresher Model/थ्रेशर मॉडल चुने</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={handleChange}
                    name="product"
                    className="form-control"
                    value={values.product}
                    isInvalid={!!errors.product}
                  >
                    <option value="">Select Thresher Model/थ्रेशर मॉडल चुने</option>
                    {!!getEnabledProductsData?.list && getEnabledProductsData.list.map((data, index) => {
                      return (
                        <option value={data.id} key={index}>{data.name}</option>
                      );  
                    })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                      {errors.product}
                    </Form.Control.Feedback>
                </Col>
                </Row>
                {
                  values.product && <Row>
                  <img className="height-200-w-auto" src={!!getEnabledProductsData?.list && getEnabledProductsData.list.find((option) => option?.id === values?.product)?.image} alt="Selected" />
                  </Row>
                }
                <Row className="mb-3">
                <Col md={12} sm={12}>
                  <Form.Group >
                    <Form.Label>Complaint/शिकायत</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your Complaint......"
                      name="summery"
                      value={values.summery}
                      onChange={handleChange}
                      isInvalid={!!errors.summery}
                      as="textarea" rows={3}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.summery}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                </Row>
                {(localStorage.getItem("userRole") === "manager" || localStorage.getItem("userRole") === "superadmin"  || localStorage.getItem("userRole") === "admin" ) && <Row className="mb-3">
                <Col md={12} sm={12}>
                  <Form.Group >
                    <Form.Label>Complaint Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Complaint Address......"
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                      isInvalid={!!errors.address}
                      as="textarea" rows={2}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.address}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                </Row>
                }
                <Row>
                  <Col md={4} sm={12} className="mb-3">
                    <Form.Group as={Col} md="12">
                      <Form.Label>Attach Image/फोटो जोड़ें</Form.Label>
                      <Row className="d-flex">
                        <Col md={10} xs={10} sm={10}>
                              {(photoCamera && img === null) && <>
                                  <Webcam
                                    audio={false}
                                    // mirrored={true}
                                    width={'100%'}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    videoConstraints={{
                                      ...videoConstraints,
                                      facingMode
                                    }}
                                  />
                                  <Row className="text-center">
                                    <Col md={6} sm={6} xs={6}>
                                    <Button onClick={capture}>Capture Photo</Button>
                                    </Col>
                                    <Col md={6} sm={6} xs={6}>
                                    <Button onClick={flipCamera}>Rotate Camera</Button>
                                    </Col>
                                  </Row>
                                </>
                              }
                              {(img === null) && <Form.Control
                                id="myfile"
                                type="file"
                                name="image"
                                onChange={(event) => {
                                  const complaintImg = event.target.files[0];
                                  setFieldValue("image", complaintImg);
                                }}
                                isInvalid={!!errors.image}
                              />
                              }
                              {img !== null && <>
                                    <img src={img} alt="screenshot" />
                                    <Button onClick={() => setImg(null)}>Retake</Button>
                                  </>
                              }
                        </Col>
                        <Col md={2} xs={2} sm={2}>
                        {!photoCamera ? <Button onClick={() => setPhotoCamera(true)}><i className="bi bi-camera-fill"></i></Button> : 
                      <Button onClick={() => setPhotoCamera(false)} variant="danger"><i className="bi bi-x-lg"></i></Button>}
                        </Col>
                      </Row>
                      <Form.Control.Feedback type="invalid">
                        {errors.image}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4} sm={12} className="mb-3">
                    <Form.Group as={Col} md="12">
                      <Form.Label>Voice Record/आवाज रिकॉर्ड</Form.Label>
                      <Row className="d-flex">
                        <Col md={10} xs={10} sm={10}>
                          {
                            audioStatus ?
                            <VoiceRecorder audioRecord={audioRecord} setAudioRecord={setAudioRecord}/> :
                            <Form.Control
                            id="complaintVoice"
                            type="file"
                            name="voice"
                            onChange={(event) => {
                              const complaintVoice = event.target.files[0];
                              setFieldValue("voice", complaintVoice);
                            }}
                            isInvalid={!!errors.voice}
                          />
                          }
                          </Col>
                          <Col md={2} xs={2} sm={2}>
                          {!audioStatus ? <Button onClick={() => setAudioStatus(true)}><i className="bi bi-mic-fill"></i></Button> : 
                            <Button onClick={() => setAudioStatus(false)} variant="danger"><i className="bi bi-x-lg"></i></Button>}
                          </Col>
                        </Row>
                        </Form.Group>
                  </Col>
                  <Col md={4} sm={12} className="mb-3">
                    <Form.Group as={Col} md="12">
                      <Form.Label>Attach Video/वीडियो जोड़ें</Form.Label>
                      <Row className="d-flex">
                        <Col md={10} xs={10} sm={10}>
                          {videoCamera ? <VideoRecorder recordedChunks={recordedChunks} setRecordedChunks = {setRecordedChunks}/> : 
                              <Form.Control
                                  id="complaintvideo"
                                  type="file"
                                  name="video"
                                  onChange={(event) => {
                                    const complaintvideo = event.target.files[0];
                                    setFieldValue("video", complaintvideo);
                                  }}
                                  isInvalid={!!errors.video}
                                /> }
                      </Col>
                      <Col md={2} xs={2} sm={2}>
                      {!videoCamera ? <Button onClick={() => setVideoCamera(true)}><i className="bi bi-camera-reels-fill"></i></Button> : 
                      <Button onClick={() => setVideoCamera(false)} variant="danger"><i className="bi bi-x-lg"></i></Button>}
                      </Col>
                      </Row>
                      <Form.Control.Feedback type="invalid">
                        {errors.video}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              <Modal.Footer className="mb-5">
                <Button className="w-100 mb-5" variant="success" type="submit">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
        </div>
    </>
  );
}

export default ComplaintAdd;

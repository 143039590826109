// common Functions

//rating(count start)
export const rating = (count) => {
  var repeatedString = "";
  while (count > 0) {
    repeatedString += "⭐";
    count--;
  }
  return (
    <ul className="p-0 m-0"
      dangerouslySetInnerHTML={{ __html: !!repeatedString && repeatedString }}
    />
  );
};
// get local user role
export const userRole = localStorage.getItem("userRole");
import React from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userLogOut } from "../reducers/commonReducer";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import AP from "../images/logo.jpg";

const HeaderMenu = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = async () => {
    const apiResponse = await dispatch(userLogOut());
    if (apiResponse?.payload?.status === 1) {
      localStorage.clear();
      return navigate("/");
    }
    return null;
  };
  return (
    <>
      <div className="container">
        <div className="row mt-3 mb-3 align-baseline">
          <div className="col-6">
            <Navbar.Brand>
              <NavLink to="/dashboard">
                <img src={AP} />
              </NavLink>
            </Navbar.Brand>
          </div>
          <div className="col-6 text-end">
            <Button type="submit" className="button-color" onClick={logout}>
              Log Out{" "}
            </Button>
          </div>
        </div>
      </div>

      <Navbar
        collapseOnSelect
        className="header-bg-color"
        expand="lg"
        variant="dark"
      >
        <Container>
          {/* <Navbar.Brand>
            <NavLink to="/dashboard" className="nav-item fs-3">
              <b>SABKA MUNAFA</b>
            </NavLink>
          </Navbar.Brand> */}
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto menu-bar dashboard">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                {
                  (localStorage.getItem("userRole") === "admin" || localStorage.getItem("userRole") === "superadmin") && <>
                  <li className="nav-item">
                  <NavLink to="/dashboard">
                    <i className="bi bi-people"></i>
                    Dashborad
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/users">
                    <i className="bi bi-people"></i>
                    Users
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/complaints">
                    <i className="bi bi-people"></i>
                    Complaints
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/products">
                    <i className="bi bi-bag-dash"></i>
                    Products
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/categories">
                    <i className="bi bi-bookmark-plus"></i>
                    Categories
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/types">
                    <i className="bi bi-bookmark-plus"></i>
                    Types
                  </NavLink>
                </li>
                  </>
                }
                {/* manager menu */}
                {
                  localStorage.getItem("userRole") === "manager" && <>
                   <li className="nav-item">
                  <NavLink to="/dashboard">
                    <i className="bi bi-people"></i>
                    Dashborad
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/users">
                    <i className="bi bi-people"></i>
                    User
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/complaints">
                    <i className="bi bi-people"></i>
                    Complaints
                  </NavLink>
                </li>
                  </>
                }
                {/* technician menu */}
                {
                  localStorage.getItem("userRole") === "technicain" && <>
                 
                <li className="nav-item">
                  <NavLink to="/complaints">
                    <i className="bi bi-people"></i>
                    Complaints
                  </NavLink>
                </li>
                  </>
                }
                {/* customer menu */}
                {
                  localStorage.getItem("userRole") === "customer" && <>
                <li className="nav-item">
                  <NavLink to="/complaints">
                    <i className="bi bi-people"></i>
                    Complaints
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/complaint">
                    <i className="bi bi-people"></i>
                    Add Complaints
                  </NavLink>
                </li>
                  </>
                }
              </ul>
            </Nav>
            <Nav className="d-none">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item"></li>
                <li className="nav-item settings">
                  <NavLink to="/settings">
                    Settings{" "}
                    <span>
                      <i className="bi bi-gear-fill"></i>
                    </span>
                  </NavLink>
                </li>
              </ul>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default HeaderMenu;

import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import {
  postApi,
  messages,
  postApiFile,
  fileWithObjArray,
} from "../helpers/apiStructure";

const initialState = {
  localStorageCartItem: !!localStorage.getItem("cartItem")
    ? JSON.parse(localStorage.getItem("cartItem"))
    : [],
  //getComplaintList
  getComplaintList: [],
  getComplaintListBlank:[],
  getComplaintCount:'',
  // users
  getUsersData: [],
  getUsersDataBlank:[],
  technicainsList:[],
  userCount:'',
  // Dashboard
  getDashboardData: [],
  // State
  getStatesData: [],
  // City
  getCitiesData: [],
  // Product Mgt
  getProductsData: [],
  getEnabledProductsData: [],
  // Categories Mgt
  getCategoriesData: [],
  getEnabledCategoriesData: [],
    // Type Mgt
    getTypesData: [],
    getEnabledTypesData: [],
  //settings
  getSettingsList: [],
};

var baseUrl = "http://localhost:3000";
// In case instead of localhost
if (window.location.host) {
  baseUrl = window.location.protocol + "//" + window.location.host;
}

//dashboard
export const getDashboard = createAsyncThunk("getDashboard", async (body) => {
  const result = await postApi(`${baseUrl}/api/dashboard`, "get", body);
  return result;
});
//users
export const getUsers = createAsyncThunk("getUsers", async (body) => {
  const result = await postApi(`${baseUrl}/api/users/main`, "post", body);
  return result;
});
//resetdata
export const resetUsers = createAsyncThunk("resetUsers", async (data) => {
  return [];
});
//technicains
export const getTechnicains = createAsyncThunk("getTechnicains", async (body) => {
  const result = await postApi(`${baseUrl}/api/technicains`, "post", body);
  return result;
});
//complaint
export const getComplaint = createAsyncThunk("getComplaint", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/complaint/main/list`,
    "post",
    body
  );
  return result;
});
export const addComplaint = createAsyncThunk("addComplaint", async (body) => {
  const result = await postApiFile(
    `${baseUrl}/api/complaint/add`,
    "post",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
export const addComplaintLive = createAsyncThunk("addComplaintLive", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/complaint/add`,
    "post",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
export const updateComplaint = createAsyncThunk("updateComplaint", async (body) => {
  const result = await postApiFile(
    `${baseUrl}/api/complaint/edit/${body?.id}`,
    "put",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
export const assignComplaint = createAsyncThunk("assignComplaint", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/assign/complain`,
    "post",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
export const complaintFeedback = createAsyncThunk("complaintFeedback", async (body) => {
  const result = await postApiFile(
    `${baseUrl}/api/complaint/feedback`,
    "post",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
//resetdata
export const resetComplaints = createAsyncThunk("resetComplaints", async (data) => {
  return [];
});
// Products Mgt
export const getProducts = createAsyncThunk("getProducts", async (body) => {
  const result = await postApi(`${baseUrl}/api/products/main`, "post", body);
  return result;
});
export const addProduct = createAsyncThunk("addProduct", async (body) => {
  const result = await postApiFile(`${baseUrl}/api/product/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateProduct = createAsyncThunk("updateProduct", async (body) => {
  const result = await postApiFile(
    `${baseUrl}/api/product/update/${body?.id}`,
    "put",
    body
  );
  return result;
});
export const getEnabledProducts = createAsyncThunk(
  "getEnabledProducts",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/products`, "post", body);
    return result;
  }
);
// Categories Mgt
export const getCategories = createAsyncThunk("getCategories", async (body) => {
  const result = await postApi(`${baseUrl}/api/main/categories`, "post", body);
  return result;
});
export const addCategory = createAsyncThunk("addCategory", async (body) => {
  const result = await postApi(`${baseUrl}/api/category/add`, "post", body);
  return result;
});
export const updateCategory = createAsyncThunk(
  "updateCategory",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/category/update/${body?.id}`,
      "put",
      body
    );
    return result;
  }
);
export const getEnabledCategories = createAsyncThunk(
  "getEnabledCategories",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/categories/`, "post", body);
    return result;
  }
);
// Types Mgt
export const getTypes = createAsyncThunk("getTypes", async (body) => {
  const result = await postApi(`${baseUrl}/api/main/types`, "post", body);
  return result;
});
export const getEnabledTypes = createAsyncThunk(
  "getEnabledTypes",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/types`, "post", body);
    return result;
  }
);
export const addType = createAsyncThunk("addType", async (body) => {
  const result = await postApi(`${baseUrl}/api/type/add`, "post", body);
  return result;
});
export const updateType = createAsyncThunk("updateType", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/type/update/${body?.id}`,
    "put",
    body
  );
  return result;
});
//send otp
export const sendOtp = createAsyncThunk("sendOtp", async (body) => {
  const result = await postApi(`${baseUrl}/api/otpSend`, "post", body);
  result?.status === 1 && localStorage.setItem("otpPhone", body?.phone);
  await messages(result?.message, result?.status);
  return result;
});
// Login
export const adminLogin = createAsyncThunk("adminLogin", async (body) => {
  const result = await postApi(`${baseUrl}/auth/login`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});

export const verifyOtp = createAsyncThunk("verifyOtp", async (body) => {
  const result = await postApi(`${baseUrl}/api/otpVerify`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const createNewPassword = createAsyncThunk(
  "createNewPassword",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/user/forgetPassword`,
      "post",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const userLogOut = createAsyncThunk("userLogOut", async (body) => {
  const result = await postApi(`${baseUrl}/auth/logout`, "get", body);
  return result;
});
export const userSignup = createAsyncThunk("userSignup", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/signup`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
//user Profile
export const userProfile = createAsyncThunk("userProfile", async (body) => {
  const result = await postApi(`${baseUrl}/api/getuser`, "get", body);
  return result;
});
export const updateUserProfile = createAsyncThunk(
  "updateUserProfile",
  async (body) => {
    const result = await fileWithObjArray(
      `${baseUrl}/api/user/updateprofile`,
      "put",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
// settings
export const getSettings = createAsyncThunk("getSettings", async (body) => {
  const result = await postApi(`${baseUrl}/api/settings`, "post", body);
  return result;
});
export const addSettings = createAsyncThunk("addSettings", async (body) => {
  const result = await postApi(`${baseUrl}/api/setting/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateSetting = createAsyncThunk("updateSetting", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/setting/update/${body?.id}`,
    "put",
    body
  );
  // await messages(result?.message, result?.status);
  return result;
});

export const addUser = createAsyncThunk("addUser", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});

export const updateUser = createAsyncThunk("updateUser", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/user/update/${body?.id}`,
    "put",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});

// State
export const getStates = createAsyncThunk("getStates", async (body) => {
  const result = await postApi(`${baseUrl}/api/statecity/states`, "post", body);
  return result;
});

// Cities
export const getCities = createAsyncThunk("getCities", async (body) => {
  const result = await postApi(`${baseUrl}/api/statecity/cities`, "post", body);
  return result;
});

const userReducer = createReducer(
  {
    // counter: 0,
    // sumOfNumberPayloads: 0,
    // unhandledActions: 0,
  },
  (builder) => {
    builder
      // Login
      .addCase(adminLogin.fulfilled, (state, action) => {
        if (action.payload.status === 1) {
          localStorage.setItem("x-auth-token", action.payload.token);
          localStorage.setItem("userRole", action.payload?.me?.role?.slug);
          localStorage.setItem("userid", action.payload?.me?.id);
        }
      })
      // Dashboard
      .addCase(getDashboard.fulfilled, (state, action) => {
        state.getDashboardData = action.payload;
      })
      // State
      .addCase(getStates.fulfilled, (state, action) => {
        state.getStatesData = action.payload;
      })
      // Cities
      .addCase(getCities.fulfilled, (state, action) => {
        state.getCitiesData = action.payload;
      })
      //user Profile
      .addCase(userProfile.fulfilled, (state, action) => {
        state.userProfileData = action.payload?.user;
        state.userSelectProduct = action.payload?.selectedProducts;
      })
      // User list
      .addCase(getUsers.fulfilled, (state, action) => {
        if (!Array.isArray(state.getUsersData)) {
            state.getUsersData = [];
          }
        const newUserList = Array.isArray(action.payload.list) ? action.payload.list : [];
        state.getUsersData = [...state.getUsersData, ...newUserList];
        state.getUsersDataBlank = action.payload.list;
        state.userCount = action.payload?.count
      })
      //reset users
      .addCase(resetUsers.fulfilled, (state, action) => {
        state.getUsersData = [];
      })
      //Technicains
      .addCase(getTechnicains.fulfilled, (state, action) => {
        state.technicainsList = action.payload?.list;
      })
      //Complaint
      .addCase(getComplaint.fulfilled, (state, action) => {
        state.getComplaintList = [...state.getComplaintList, ...action.payload?.list];
        state.getComplaintListBlank = action.payload?.list;
        state.getComplaintCount = action.payload?.count;
      })
      //reset Complaint
      .addCase(resetComplaints.fulfilled, (state, action) => {
        state.getComplaintList = [];
      })
      // Products list
      .addCase(getProducts.fulfilled, (state, action) => {
        state.getProductsData = action.payload;
      })
      .addCase(getEnabledProducts.fulfilled, (state, action) => {
        state.getEnabledProductsData = action.payload;
      })
      // Categories list
      .addCase(getCategories.fulfilled, (state, action) => {
        state.getCategoriesData = action.payload;
      })
      .addCase(getEnabledCategories.fulfilled, (state, action) => {
        state.getEnabledCategoriesData = action.payload;
      })
            // Types list
            .addCase(getTypes.fulfilled, (state, action) => {
              state.getTypesData = action.payload;
            })
            .addCase(getEnabledTypes.fulfilled, (state, action) => {
              state.getEnabledTypesData = action.payload?.list;
            })
      .addCase(userSignup.fulfilled, (state, action) => {
        action.payload.status === 1 &&
          localStorage.setItem("userSignupStatus", action.payload.status);
      })
      //settings
      .addCase(getSettings.fulfilled, (state, action) => {
        state.getSettingsList = action.payload?.list;
      })
      
  }
);

export default userReducer;

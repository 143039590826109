import React from "react";
import { Col, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import logo from "../../images/logo.jpg";
import {
  userSignup,
} from "../../reducers/commonReducer";

const schema = yup.object().shape({
  name: yup.string().required(),
  phone: yup.string().min(10).max(10).required(),
  email: yup.string().email("Invalid email address").required(),
  address: yup.string().required("Please Enter Address."),
  city: yup.string().required("Please Enter city."),
  state: yup.string().required("Please Enter state."),
  zipcode: yup.string().required("Please Enter PIN code."),
  password: yup.string().required("Please Enter Password.")
});

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const api = async (values) => {
    const apiResponse = await dispatch(userSignup(values));
    if (apiResponse?.payload?.status === 1) {
      return setTimeout(() => {
        navigate("/");
      }, 1000);
    }
    return null;
  };

  return (
    <>
      <div className="content sign-up">
        <div className="row sign-up">
          <div className="col-md-5  login-form p-4">
            <div className="text-center mt-2">
              <img src={logo} alt="logo" />
            </div>
            <div className="text-center">
              <p>
                <b> Sign Up / नया खाता बनाएँ</b>
              </p>
            </div>

            <Formik
              validationSchema={schema}
              onSubmit={(values) => {
                dispatch(api(values));
              }}
              initialValues={{
                name: "",
                phone: "",
                email: "",
                address:"",
                city:'',
                state:'',
                zipcode:'',
                password: "",
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <div className="col-12 mt-4">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-person-fill"></i>
                        </div>
                        <Form.Control
                          type="text"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isInvalid={!!errors.name}
                          className="form-control"
                          placeholder="Enter Name"
                          required
                        />
                         <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-12 mt-2 mb-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-phone-fill"></i>
                        </div>
                        <Form.Control
                          type="text"
                          name="phone"
                          value={values.phone}
                          onChange={handleChange}
                          isInvalid={!!errors.phone}
                          className="form-control"
                          placeholder="Phone"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                        {errors.phone}
                      </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-12 mt-2 mb-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-envelope-fill"></i>
                        </div>
                        <Form.Control
                          type="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                          className="form-control"
                          placeholder="Email"
                          required
                        />
                         <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-12  mb-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-piggy-bank-fill"></i>
                        </div>
                        <Form.Control
                          type="text"
                          name="address"
                          value={values.address}
                          onChange={handleChange}
                          isInvalid={!!errors.address}
                          className="form-control"
                          placeholder="Enter Address"
                          required
                        />
                         <Form.Control.Feedback type="invalid">
                        {errors.address}
                      </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-12  mb-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-piggy-bank-fill"></i>
                        </div>
                        <Form.Control
                          type="text"
                          name="city"
                          value={values.city}
                          onChange={handleChange}
                          isInvalid={!!errors.city}
                          className="form-control"
                          placeholder="Enter City"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                        {errors.city}
                      </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-12  mb-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-piggy-bank-fill"></i>
                        </div>
                        <Form.Control
                          type="text"
                          name="state"
                          value={values.state}
                          onChange={handleChange}
                          isInvalid={!!errors.state}
                          className="form-control"
                          placeholder="Enter State"
                          required
                        />
                         <Form.Control.Feedback type="invalid">
                        {errors.state}
                      </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-12  mb-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-piggy-bank-fill"></i>
                        </div>
                        <Form.Control
                          type="text"
                          name="zipcode"
                          value={values.zipcode}
                          onChange={handleChange}
                          isInvalid={!!errors.zipcode}
                          className="form-control"
                          placeholder="Enter PIN Code"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                        {errors.zipcode}
                      </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-12  mb-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-lock-fill"></i>
                        </div>
                        <Form.Control
                          type="password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={!!errors.password}
                          className="form-control"
                          placeholder="Password"
                          required
                        />
                         <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-12 text-center">
                    <Button className="btn-color w-100" type="submit">
                      Sign Up
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="col-12 text-center">
            <p>
              <Link to={"/"}>
                <b>
                  <i className="bi bi-arrow-left"></i> Back to Home
                </b>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;

import React, {useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Update from "./Update";
import Form from "react-bootstrap/Form";
import {
  getUsers,getEnabledTypes, resetUsers
} from "../../reducers/commonReducer";
import red from "../../images/red.png";
import green from "../../images/green.png";

const List = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [role, setRole] = useState("");
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getEnabledTypes())
  }, []);
  useEffect(() => {
    let timeOut = setTimeout(() => {
      dispatch(resetUsers())
    dispatch(getUsers({
      search
    }));
    }, 800);
    return () => clearTimeout(timeOut);
  }, [search]);
  const { getUsersData, getUsersDataBlank, userCount } = useSelector((state) => state.commonReducer);
  return (
    <>
      <Header />
      <div className="container">
        <div className="mt-4 row">
          <div className="col-md-6 col-6">
            <h3>Users List ({userCount})</h3>
          </div>
          <div className="col-md-6 text-end col-6">
            <Button
              className="mb-3 mobile-width submit-buttom"
              onClick={() => navigate("/add-user")}
            >
              Add User
            </Button>
          </div>
          <div className="col-md-12 p-0">
                  <Row className=" mb-3 border pt-3 pb-3 justify-content-between">
                    <Col md={4} sm={12}>
                      <Form.Label>Name, City, State</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search..."
                        name="name"
                        onChange={(e) => {
                            setSearch(e.target.value)
                        }}
                      />
                    </Col>
                    <Col md={3} sm={12}>
                      <Form.Label>Role</Form.Label>
                      <Form.Control
                        as="select"
                        name="role"
                        value={role}
                        onChange={(e) => {
                          dispatch(resetUsers())
                          dispatch(getUsers({
                            role:e.target.value,
                          }))
                          setRole(e.target.value)
                        }}
                        className="form-control"
                      >
                        <option value="">All</option>
                        <option value="customer">Customer</option>
                        <option value="technician">Technician</option>
                        {(localStorage.getItem("userRole") === "admin" || localStorage.getItem("userRole") === "superadmin") && <option value="manager">Manager</option>}
                      </Form.Control>
                    </Col>
                    <Col md={3} sm={12}>
                    </Col>
                    <Col md={2}>
                      <Button className="mt-4 float-right" variant="danger" onClick={() => window.location.reload()}>Refresh</Button>
                      </Col>
                  </Row>
                
          </div>
          <div className="overflow-auto border mt-3">
            <Table striped hover size="sm" className="overflow-scroll">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>Role</th>
                  <th>Type</th>
                  {/* <th>Complains</th> */}
                  <th>Email</th>
                  <th>Address</th>
                  <th>Status</th>
                  <th>Complains</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {!!getUsersData &&
                  getUsersData.map((data, index) => {
                    return (
                      <tr key={data?.id}>
                        <td>{index + 1}.</td>
                        <td>
                          {data?.name} ({data?.username})<br/>
                          <b>{data?.realPassword}</b>
                        </td>
                        <td>{data?.phone}</td>
                        <td><span className={
                          data?.role?.slug === "manager" && "text-success" || 
                          data?.role?.slug === "customer" && "text-warning" ||
                          data?.role?.slug === "technicain" && "text-primary"}><b>{data?.role?.name}</b></span></td>
                        <td>{data?.type?.map((data, index) => <span key={index}><b>{`${data?.name}`}</b><br/></span>)}</td>
                        {/* <td>{data?.complains}</td> */}
                        <td>{data?.email}</td>
                        <td>{`${data?.address}, ${data?.city}, ${data?.state}`}</td>
                        <td className="table-img">
                          {data?.status === true ? (
                            <img src={green} />
                          ) : (
                            <img src={red} />
                          )}
                        </td>
                        <td>
                          {
                            data?.role?.slug === "manager" && <Button variant="info" onClick={() => navigate(`/complaints?mngrole=${data?.type?.map((data) => data?.id)}`)}><i className="bi bi-eye-fill"></i></Button>
                          }
                          {
                            data?.role?.slug === "customer" && <>
                            <Button variant="success" onClick={() => navigate(`/complaint?userid=${data?.id}`)}><i className="bi bi-plus-lg"></i></Button> <Button variant="info" onClick={() => navigate(`/complaints?customerid=${data?.id}`)}><i className="bi bi-eye-fill"></i></Button></>
                          }
                          {
                            data?.role?.slug === "technicain" && <Button variant="info" onClick={() => navigate(`/complaints?assignby=${data?.id}`)}><i className="bi bi-eye-fill"></i></Button>
                          }
                          </td>
                        <td>
                          <Update
                            fName={data?.name}
                            userAdrs={data?.address}
                            userCity={data?.city}
                            userState={data?.state}
                            userPhone={data?.phone}
                            userEmail={data?.email}
                            status={data?.status}
                            id={data?.id}
                            userRole={data?.role?.slug}
                            userType={data?.type}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </div>
        <Row>
          <Col>
            {!!getUsersDataBlank && getUsersDataBlank.length > 0 ? (
              <Button
                className="w-100 button-color"
                onClick={() => {
                  setPage(page + 1);
                  dispatch(
                    getUsers({
                      page: page + 1,
                      role
                    })
                  );
                }}
              >
                Load More
              </Button>
            ) : (
              <p className="text-center">
                <b>No record found 😔.</b>
              </p>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default List;

import { json } from "react-router-dom";
import { toast } from "react-toastify";

var apiMessage = "";
export const messages = async (msg, apiStatus) => {
  // apiMessage = msg
  // console.log("apiMessage", apiMessage)
  // await toast.error(msg)
  if (apiStatus === 1) {
    toast.success(msg );
  } else {
    toast.error(msg );
  }
};

export const postApi = async (api, type, body, successMsg) => {
  const res = await fetch(api, {
    method: type,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": localStorage.getItem("x-auth-token"),
    },
    body: JSON.stringify(body),
  });
  return await res.json();
};

export const allApi = async (api, type, successMsg) => {
  const res = await fetch(api, {
    method: type,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": localStorage.getItem("x-auth-token"),
    },
  });
  return await res.json();
};

export const postApiFile = async (api, type, userDetails) => {
  const formData = new FormData();
  Object.entries(userDetails).map(([key, value]) => {
    formData.append(key, value);
  });
  const myHeaders = new Headers();
  myHeaders.append("x-auth-token", localStorage.getItem("x-auth-token"));
  const res = await fetch(api, {
    method: type,
    body: formData,
    headers: myHeaders,
  });
  return await res.json();
};

export const fileWithObjArray = async (api, type, userDetails) => {
  const formData = new FormData();

  const fields = [
    "firstName",
    "firmName",
    "lastName",
    "address",
    "city",
    "email",
    "gst",
    "gstimage",
    "image",
    "password",
    "state",
  ];

  fields.forEach((field) => {
    if (userDetails?.[field]) {
      formData.append(field, userDetails[field]);
    }
  });

  // if (userDetails?.products) {
  //   formData.append("products", JSON.stringify(userDetails.products));
  // }
  if (userDetails?.EDIBLE) {
    formData.append("EDIBLE", JSON.stringify(userDetails.EDIBLE));
  }
  if (userDetails?.NONEDIBLE) {
    formData.append("NONEDIBLE", JSON.stringify(userDetails.NONEDIBLE));
  }
  if (userDetails?.CHEMICAL) {
    formData.append("CHEMICAL", JSON.stringify(userDetails.CHEMICAL));
  }
  if (userDetails?.brokers) {
    formData.append("brokers", JSON.stringify(userDetails.brokers));
  }

  const myHeaders = new Headers();
  myHeaders.append("x-auth-token", localStorage.getItem("x-auth-token"));

  const res = await fetch(api, {
    method: type,
    body: formData,
    headers: myHeaders,
  });

  return await res.json();
};

// export const fileWithObjArray = async (api, type, userDetails) => {
//   const formData = new FormData();
//   userDetails?.firstName &&
//     formData.append("firstName", userDetails?.firstName);
//   userDetails?.firmName && formData.append("firmName", userDetails?.firmName);
//   userDetails?.lastName && formData.append("lastName", userDetails?.lastName);
//   userDetails?.address && formData.append("address", userDetails?.address);
//   userDetails?.city && formData.append("city", userDetails?.city);
//   userDetails?.email && formData.append("email", userDetails?.email);
//   userDetails?.gst && formData.append("gst", userDetails?.gst);
//   userDetails?.gstimage && formData.append("gstimage", userDetails?.gstimage);
//   userDetails?.image && formData.append("image", userDetails?.image);
//   userDetails?.password && formData.append("password", userDetails?.password);
//   userDetails?.state &&
//     formData.append(
//       "state",
//       userDetails?.state == "undefined" ? "" : userDetails?.state
//     );
//   userDetails?.products &&
//     formData.append("products", JSON.stringify(userDetails?.products));
//   userDetails?.brokers &&
//     formData.append("brokers", JSON.stringify(userDetails?.brokers));
//   const myHeaders = new Headers();
//   myHeaders.append("x-auth-token", localStorage.getItem("x-auth-token"));
//   const res = await fetch(api, {
//     method: type,
//     body: formData,
//     headers: myHeaders,
//   });
//   return await res.json();
// };

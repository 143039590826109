import React, { useEffect, useRef, useState } from "react";
import { useNavigate,Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/Header";
import {Col, Row, Button} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { getComplaint, getTechnicains, resetComplaints } from "../../reducers/commonReducer";
import Update from "./ComplaintUpdate";
import { rating} from "../../const";
import Card from 'react-bootstrap/Card';
import dummy from '../../images/dummy.png'

const List = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const width = useRef(window.innerWidth);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);
  const queryParameters = new URLSearchParams(window.location.search);
  const assignby = queryParameters.get("assignby");
  const complaintsid = queryParameters.get("complaintsid");
  const mngroletype = queryParameters.get("mngrole");
  const productId = queryParameters.get("productId");
  const customerid = queryParameters.get("customerid");

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    if(localStorage.getItem("userRole") === "admin" || localStorage.getItem("userRole") === "superadmin" || localStorage.getItem("userRole") === "manager"){
      dispatch(getTechnicains())
    }
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
        },
        (err) => {
          setError(err.message);
        }
      );
    } else {
      setError("Geolocation is not available in this browser.");
    }
  }, []);
  useEffect(() => {
    let timeOut = setTimeout(() => {
      dispatch(resetComplaints())
      dispatch(getComplaint({
        assignedBy: assignby,
        type:!!mngroletype ? mngroletype.split(',') : null,
        product : productId,
        category:complaintsid,
        customer:customerid,
        search
      }));
    }, 800);
    return () => clearTimeout(timeOut);
  }, [search]);
  const { getComplaintList, getComplaintListBlank, getComplaintCount } = useSelector((state) => state.commonReducer);
  return (
    <>
      <Header />
      {/* <GetLocation/> */}
      <div className="container">
        <div className="mt-4 mb-2 overflow-auto">
          <Col md={12}>
            <h3>Complaint List ({getComplaintCount})</h3>
          </Col>
          <hr />
          <Row className="p-0 m-0">
          <Col md={4} sm={4} xs={4}>
          <label>Searching</label>
            <Form.Control
              type="text"
              placeholder="Search by ComplainId..."
              className="me-2"
              aria-label="Search"
              onChange={(e) => {
               setSearch(e.target.value)
              }}
            />
          </Col>
          <Col md={3} sm={3} xs={3}>
            <label>StartDate</label>
            <Form.Control
              type="date"
              placeholder="Search by ComplainId..."
              className="me-2"
              aria-label="Search"
              onChange={(e) => {
               setStartDate(e.target.value)
              }}
            />
          </Col>
          <Col md={3} sm={3} xs={3}>
          <label>EndDate</label>
            <Form.Control
              type="date"
              placeholder="Search by ComplainId..."
              className="me-2"
              aria-label="Search"
              onChange={(e) => {
                setEndDate(e.target.value)
              }}
            />
          </Col>
          <Col md={1} sm={1} xs={1}>
             <Button className="float-right mt-4" variant="success" onClick={() =>{
              dispatch(resetComplaints())
              dispatch(getComplaint({
                assignedBy: assignby,
                type:!!mngroletype ? mngroletype.split(',') : null,
                product : productId,
                category:complaintsid,
                customer:customerid,
                startDate,
                endDate
              }));
             }}>Submit</Button>
          </Col>
          <Col md={1} sm={1} xs={1}>
             <Button className="float-right mt-4" variant="danger" onClick={() => window.location.reload()}>Refresh</Button>
          </Col>
          </Row>
        </div>
        <div className="overflow-auto row mt-2">
       { !!getComplaintList
                ? getComplaintList.map((data, index) => {
                    return (
                      <Col md={4} key={index}>
                       <Card>
                        <Card.Body className={`${width.current < 668 ? "mb-2" : "mb-5"} box-shadow ${data?.status === 3 && "bg-info"}`}>
                          <Row>
                            <Col md={4} sm={4} xs={4}>{index + 1}.</Col>
                            <Col md={4} sm={4} xs={4}>{data?.status === 0 && <span className="text-primary"><b>Pending</b></span> ||
                              data?.status === 1 && <span className="text-warning"><b>Assigned</b></span> ||
                              data?.status === 2 && <span className="text-secondary"><b>Review</b></span> ||
                              data?.status === 3 && <span className="text-light"><b>Completed</b></span> ||
                              data?.status === 4 && <span className="text-primary"><b>Progress</b></span>}
                            </Col>
                            <Col md={4} sm={4} xs={4}>
                            {
                            (localStorage.getItem("userRole") === "technicain" && (data?.status === 1 || data?.status === 4)) &&  
                            <Update
                              title="Complaint Status and Client Review"
                              complaintid={data?.id}
                              location={location}
                            />
                            }
                            {/* {(localStorage.getItem("userRole") === "manager" && data?.status !== 3) && <Update
                            title="Assign Technician"
                            complaintid={data?.id}
                            complaintStatus={data?.status}
                            />
                              } */}
                            {((localStorage.getItem("userRole") === "admin" || localStorage.getItem("userRole") === "superadmin" || localStorage.getItem("userRole") === "manager") && data?.status !== 3) && <Update
                                    title="Assign Technician"
                                    complaintid={data?.id}
                                    complaintStatus={data?.status}
                                  />
                            }                       
                            {
                           (((localStorage.getItem("userRole") === "customer") && data?.status > 1 && data?.status < 3) &&  
                            <Update
                              title="Complaint Status and Client Review"
                              complaintid={data?.id}
                            />)
                            }
                            {!data?.address && <Button className="float-right px-2 py-1 mx-1" onClick={() => window.open(`https://www.latlong.net/c/?lat=${data?.latitude}&long=${data?.longitude}`, '_blank', 'noopener,noreferrer')}><i className="bi bi-geo-alt-fill"></i></Button>}
                            </Col>
                          </Row>
                          <hr/>
                          <p className="mb-0">ComplainId : <b>{data?.complainId}</b></p>
                          <p className="mb-0"><b>{data?.customer?.name} ({data?.customer?.username})</b></p>
                          <p className="mb-0"><i className="bi bi-geo-alt-fill"></i> <b>{`${data?.customer?.address}, ${data?.customer?.city}, ${data?.customer?.state}`}</b></p>
                         <Row>
                          <Col md={5}><i className="bi bi-telephone-fill"></i> <b>{data?.customer?.phone}</b></Col>
                          <Col md={7}><i className="bi bi-envelope-check-fill"></i> <b>{data?.customer?.email}</b></Col>
                         </Row>
                          <p className="mb-0">Product Name : <b>{data?.product?.name}</b></p>
                          <p className="mb-0">Complaint Type : <b>{data?.type?.name}</b></p>
                          <p className="mb-0">{data?.summery}</p>
                          <div className="complaint-img mt-1 mb-1">
                          {data?.image?.length > 0 ? <Link to={`/${data?.image}`} target="_blank"><img src={`/${data?.image}`}/></Link> : <img src={dummy}/>}
                          </div>
                          {<video width="100%" height="100px" controls>
                            <source src={`/${data?.video}`} type="video/mp4"/>
                          </video>}
                          {<audio controls className="w-100">
                            <source src={`/${data?.voice}`} type="audio/mpeg" />
                            </audio>}
                          {data?.address && <p className="mb-0">Complaint Address : <b>{data?.address}</b></p>}
                          <p className="mb-0">AssignBy : <b>{
                            data?.assignedBy ? <>
                             {`${data?.assignedBy?.name}(${data?.assignedBy?.username})`}
                          </> : <span className="text-danger">Please Assign Complaint</span>
                          }</b>
                           {(data?.status > 1) && <Button variant="warning" className="float-right px-2 py-1 mx-1" onClick={() => window.open(`https://www.latlong.net/c/?lat=${data?.feekback?.rLatitude}&long=${data?.feekback?.rLongitude}`, '_blank', 'noopener,noreferrer')}><i className="bi bi-geo-alt-fill"></i></Button>}
                           </p>
                          <p className="mb-0">Phone No. : <b>{
                           data?.assignedBy?.phone}</b></p>
                          <hr/>
                          {data?.feekback?.image?.length > 0 && <div className="complaint-img mt-1 mb-1">
                            <Link to={`/${data?.feekback?.image}`} target="_blank"><img src={`/${data?.feekback?.image}`} />
                            </Link></div>}
                          <span>{data?.feekback?.star > 0 && rating(data?.feekback?.star)}</span>
                          <p className="mb-0">{data?.feekback?.commet}</p>
                        </Card.Body>
                      </Card>
                      </Col>
                    )}) : "No record found" }
        </div>
        <Row>
          <Col>
            {!!getComplaintListBlank && getComplaintListBlank.length > 0 ? (
              <Button
                className="w-100 button-color"
                onClick={() => {
                  setPage(page + 1);
                  dispatch(
                    getComplaint({
                     page: page + 1,
                    assignedBy: assignby,
                    type:!!mngroletype ? mngroletype.split(',') : null,
                    product : productId,
                    category:complaintsid,
                    customer:customerid
                  })
                  )
                }}
              >
                Load More
              </Button>
            ) : (
              <p className="text-center">
                <b>No record found 😔.</b>
              </p>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default List;
